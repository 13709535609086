
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DeviceConditions',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    const device = [
      {
        title: t('device.grade1'),
        content: t('device.content1'),
      },
      {
        title: t('device.grade2'),
        content: t('device.content2'),
      },
      {
        title: t('device.grade3'),
        content: t('device.content3'),
      },
    ];
    return {
      t,
      device,
    };
  },
});
