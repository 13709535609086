<template>
  <item-wrapper id="device">
    <item-header :title="t('device.title')" />
    <main>
      <div v-for="i in device" :key="i">
        <p>
          {{ i.title }}
        </p>
        <h3>{{ i.content }}</h3>
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DeviceConditions',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    const device = [
      {
        title: t('device.grade1'),
        content: t('device.content1'),
      },
      {
        title: t('device.grade2'),
        content: t('device.content2'),
      },
      {
        title: t('device.grade3'),
        content: t('device.content3'),
      },
    ];
    return {
      t,
      device,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

:deep(header) {
  h2 {
    margin-bottom: 40px;
  }
}

main {
  @include flex-center();
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  margin-top:61px;
  div {
    margin-bottom: 20px;
    p {
      font-size: 20px;
      color: #1c1c1c;
      line-height: 23px;
      font-weight: bold;
    }
    h3 {
      font-size: 20px;
      color: #545454;
    }
  }
}
</style>
